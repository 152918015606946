$small-width: 576px;
$medium-width: 768px;
$large-width: 992px;
$extraLarge-width: 1200px;

@mixin small {
  @media screen and (min-width: #{$small-width}) {
    @content;
  }
}

@mixin medium {
  @media screen and (min-width: #{$medium-width}) {
    @content;
  }
}

@mixin large {
  @media screen and (min-width: #{$large-width}) {
    @content;
  }
}

@mixin extraLarge {
  @media screen and (min-width: #{$extraLarge-width}) {
    @content;
  }
}

@mixin gradientAnimation($primary-color, $secondary-color) {
  animation: animateBg 1s infinite linear;
  background-image: linear-gradient(
    90deg,
    $primary-color,
    $secondary-color,
    $primary-color,
    $secondary-color
  );
  background-size: 300% 100%;

  @keyframes animateBg {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
}

@mixin wrapper {
  .wrapper {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 60px 12px 0 12px;
    width: 100vw;

    @include medium {
      margin-top: 3.5rem;
      width: 768px;
    }

    @include large {
      padding: 0 10px;
      width: 886px;
    }

    @include extraLarge {
      max-width: 1440px;
      min-width: 1200px;
      padding: 0;
    }

    @content;
  }
}
