@import "../../../assets/styles/Mixin.scss";
@import "../../../assets//styles/Variables.scss";

.Footer {
  width: 100%;
  height: auto;
  background-color: $darkturquise-text;
  color: white;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  font-family: $font;

  @include medium {
    height: 492px;
    padding:  0px 20px;
  }

  @include wrapper {

    @include medium {
      padding-top: 0;
    }
   
    > .logo-section {
      width: 170px;
      height: 48px;
      margin-bottom: 20px;
    }

    > .main-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-bottom: 0;

      @include medium {
        padding-bottom: 60px;
        width: 600px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .title {
        font-size: 16px;
        font-weight: $bold;
        line-height: 28px;
      }

      ul {
        padding-left: 0;
        li {
          list-style: none;
          padding-bottom: 20px;

          a, p {
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -1.7%;
            text-decoration: none;
            color: white;
          }

          > p {
            margin: 0;
          }
        }
      }
    }

    > .bottom-section {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;

      @include medium {
        width: 462px;
        flex-direction: row;
        padding-bottom: 0;
      }

      a {
        font-size: 14px;
        text-decoration: none;
        color: white;
        margin-right: 40px;
        padding-bottom: 20px;

        @include medium {
          padding-bottom: 0;
        }
      }
    }
  }
}

