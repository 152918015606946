@import '../../../assets/styles/Mixin.scss';
@import '../../../assets/styles/Variables.scss';

.MessageWorkInProgress {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;

    @include large {
        height: 500px;
    }
    

    .Message {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        
        
        

        h1 {
            width: 90%;
            max-width: 385px;
            margin-bottom: 50px;
            font-size: 32px;
            font-weight: $bold;
            color: $bold;
            text-align: center; 

            @include medium {
                max-width: none;
            }

            @include large {
                font-size: 42px;
                max-width: none;
            }
        }

        p {
            width: 90%;
            max-width: 385px;
            margin-bottom: 50px;
            color: $bold;
            font-weight: $normal;
            text-align: center;
            line-height: 28px;
            //width: 300px;

            @include medium {
                //width: 450px;
                max-width: 600px;
            }

            @include large {
                //width: 600px;
                font-size: 22px;
                max-width: 800px;
            }
        }
    }
}