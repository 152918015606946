@import "assets/styles/Mixin.scss";
@import "assets/styles/Variables.scss";

.WeAre {
  @include wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
    
    @include large {
      margin-block: 160px;
      flex-direction: row;
      justify-content: space-between;
    }

    > .we-are-img {
      max-width: 100%;
      height: auto;
      border-radius: 8px;
      filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.2));
      // margin sugerido para esta seccion ya que queda muy pegado la imagen con el texto.
      margin-left: 16px;
      margin-right: 16px;

      @include large {
        width: 384px;
        height: 484px;
      }
    }

    > .info {
      margin-top: 10px;
      @include large {
        width: 778px;
      }
      > span {
        color: $pink-darkest;
        font-size: 16px;
        font-weight: $extrabold;
      }

      > .title {
        color: $dark-turquoise;
        font-size: 32px;
        font-weight: $black;
        margin: 24px 0;
      }

      > p {
        color: $darkturquise-text;
        font-size: 18px;
        font-weight: $normal;
        line-height: 30px;
        text-align: left;
      }
    }
  }
}
