@import "../../../assets/styles/Mixin.scss";
@import "../../../assets/styles/Variables.scss";

.ContributionsProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  font-family: $font;
  box-sizing: border-box;
  padding: 40px 40px 0 40px;
  margin-top: 40px;

  @include medium {
    width: 560px;
  }
    
  @include large {
    width: 792px;
  }

  > .title {
    margin: 0;
    color: $turquoise-title;
    font-size: 32px;
    font-weight: $extrabold;
    line-height: 54px;
    letter-spacing: -1.7%;
    text-align: center;
  }

> .info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  

  @include large {
    flex-direction: row;
    justify-content: space-between;
    height: auto;
  }

  > .col {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 180px;
    max-height: 180px;
    min-height: 100px;
    margin-bottom: 40px;

    @include large {
      margin-bottom: 0;
      min-height: 180px;
    }

    button {
      width: 180px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      color: white;
      background-color: black;
      border: none;
      cursor: pointer;
      font-size: normal;
      font-weight: $bold;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -1.7%;
      text-decoration: none;
    }
  }

  .subtitle, .description {
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: $normal;
  }

  .description {
    color: $turquoise-natural;
    margin-bottom: 24px;
  }

  .info {
    font-size: 40px;
    line-height: 68px;
    margin: 0;
    font-weight: $black;
    letter-spacing: -1.7%;
    color: $turquoise-medium;
  }
} 
}