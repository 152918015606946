@import "assets/styles/Mixin.scss";
@import "assets/styles/Variables.scss";
a {
  text-decoration: none;

  .Map {
    display: grid;
    justify-content: flex-end;
    grid-template-areas: ". info-container";
    background-image: url("https://cdn.sonarescrecer.org/images/map.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 20px;
    margin-bottom: 80px;
    
    @include medium {
        padding: 76px 120px;
        margin-bottom: 160px;
    }

    > .info-container {
      background-color: $white;
      border-radius: 8px;
      box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.06);
      box-sizing: border-box;
      color: black;
      display: grid;
      grid-area: info-container;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto;
      grid-template-areas:
        "title title"
        "address email"
        "phone-number tax";
      gap: 20px 10px;
      padding: 20px;

      @include medium {
        column-gap: 130px;
        padding: 80px 60px;
        row-gap: 40px;
        max-width: 588px;
      }

      > .title {
        grid-area: title;
        color: $turquoise-title;
        font-size: 24px;
        font-weight: $extrabold;
        line-height: 40px;
        margin: 0;
      }

      > .box {
        > h5 {
          align-items: center;
          color: $turquoise-natural;
          display: flex;
          font-size: 16px;
          font-weight: $extrabold;
          margin: 0 0 8px;

          > img {
            margin-right: 11px;
          }
        }

        > p {
          font-size: 14px;
          font-weight: $normal;
          line-height: 24px;
          margin: 0;
        }
      }
    }
  }
}