@import "assets/styles/Mixin.scss";
@import "assets/styles/Variables.scss";

.LoginForm {
  border-radius: 8px;
  background-color: $gray;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 588px;
  padding: 40px 56px;
  width: 100%;
  position: relative;

  > .title {
    font-size: 24px;
    font-weight: $extrabold;
    line-height: 40px;
    letter-spacing: -1.7%;
    margin-bottom: 40px;
    margin-top: 0;
  }

  > .subtitle {
    font-size: 14px;
    line-height: 24px;
    font-weight: $normal;
    letter-spacing: -1.7%;
    margin-bottom: 6px;
  }

  input {
    height: 40px;
    box-sizing: border-box;
    margin-bottom: 28px;
    border: 1px solid #005566;
    border-radius: 5px;
  }

  .password {
    margin-bottom: 8px;
  }

  img {
    position: absolute;
    bottom: 146px;
    right: 24px;
    width: 24px;
    height: 24px;
  }

  > .forgot-text {
    text-align: right;
    font-weight: $bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -1.7%;
    margin-bottom: 24px;
  }

   button {
    width: 100%;
    height: 36px;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: white;
    background-color: $pink-natural;
    border: none;
    cursor: pointer;
    font-size: normal;
    font-weight: $bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -1.7%;
    text-decoration: none;
  }

  > .social-login {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    margin-top: 24px;
    width: 100%;

    > button{
        width: 100%;
        border-radius: 4px;
        background-color: black;
    
    }
  }
}
