@import "assets/styles/Mixin.scss";
@import "assets/styles/Variables.scss";

.Awards {
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 160px;

  @include medium {
    max-width: 792px;
  }

  > .title {
    color: $dark-turquoise;
    font-size: 36px;
    font-weight: $black;
    text-align: center;

    @include large {
      font-size: 40px;
    }
  }

  > .section {
    &.one {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 56px;

      @include medium {
        justify-content: space-around;
        flex-direction: row;
      }

      > .img-1 {
        height: 135px;
        margin-bottom: 24px;
        margin-right: 24px;
        width: 180px;

        @include small {
          height: 163px;
          margin: 0;
          width: 218px;
        }
      }

      > .img-2 {
        height: 159px;
        width: 159px;

        @include small {
          height: 218px;
          width: 218px;
        }
      }
    }

    &.two {
      background-color: $gray;
      align-items: center;
      padding: 40px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include small {
        padding: 40px 108px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas:
          "average-reviews average-reviews"
          "btn extra-info";
        row-gap: 24px;
        column-gap: 38px;
        justify-content: center;
      }

      > .average-reviews {
        grid-area: average-reviews;
        box-sizing: border-box;
        color: $turquoise-title;
        display: grid;
        grid-template-columns: 35px 177px;
        grid-template-rows: 35px auto;
        grid-template-areas:
          "star rating"
          "star reviews";
        width: 209px;
        justify-self: center;
        align-self: center;
        margin: 0 auto;
        column-gap: 22px;

        @include small {
          grid-template-columns: 35px auto;
        }

        > .star {
          grid-area: star;
          align-self: center;
        }

        > .rating {
          grid-area: rating;
          font-size: 16px;
          text-align: left;
          font-weight: 700;
          align-self: flex-end;
          margin: 0;
          line-height: 28px;
          color: $dark-turquoise;
        }

        > .reviews {
          grid-area: reviews;
          text-align: left;
          margin: 0;
          font-size: 16px;
          font-weight: 700;
          color: $dark-turquoise;
          line-height: 24px;
        }
      }

      > .btn {
        grid-area: btn;
        background-color: $pink-natural;
        border-radius: 4px;
        color: $white;
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
        height: min-content;
        margin: 24px 0;
        padding: 6px;
        width: 121px;
        text-decoration: none;

        @include small {
          margin: 0;
        }
      }

      > .extra-info {
        align-items: center;
        background-color: $white;
        border-radius: 8px;
        display: flex;
        grid-area: extra-info;
        justify-content: space-between;
        padding: 24px;

        @include small {
          justify-content: space-evenly;
          width: 417px;
        }

        > .logo {
          color: black;
          width: 120px;
        }

        > .nonprofits {
          width: 110px;
        }
      }
    }
  }
}
