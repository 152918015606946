@import "assets/styles/Mixin.scss";
@import "assets/styles/Variables.scss";

.StatisticCardInfo {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  > img {
    filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.2));
    height: auto;
    margin-bottom: 34px;
    max-height: 288px;
    max-width: 384px;
    width: 100%;
  }

  > .number {
    color: $turquoise-natural;
    font-size: 40px;
    font-weight: $black;
    margin: 0;
  }

  > .title {
    color: black;
    font-size: 16px;
    font-weight: $bold;
  }
}
