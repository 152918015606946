@import "assets/styles/Mixin.scss";
@import "assets/styles/Variables.scss";

.TeamCard {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 104px auto;
  grid-template-rows: auto;
  grid-template-areas:
    "img name"
    "img charge"
    "divider divider"
    "quote quote";
  column-gap: 24px;
  height: 100%;
  padding: 40px;
  max-width: 384px;

  > .person-image {
    grid-area: img;
    border-radius: 4px;
    height: 104px;
    margin-bottom: 24px;
    width: 104px;
  }

  > .name {
    margin: 0;
    grid-area: name;
    font-size: 16px;
    font-weight: 800;
    color: #000000;
    align-self: center;
  }

  > .charge {
    grid-area: charge;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #000000;
    margin: 0;
  }

  > .divider {
    grid-area: divider;
    border-top: 1px solid #e8edee;
  }

  > .quote {
    color: #000000;
    color: $turquoise-title;
    font-size: 12px;
    font-size: 14px;
    font-weight: $extrabold;
    grid-area: quote;
    height: 50px;
    line-height: 24px;
    margin-top: 87px;
    position: relative;

    @include medium {
      font-size: 16px;
    }

    > .quote-img {
      background-image: url(https://cdn.sonarescrecer.org/icons/icon-quote.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      left: 0;
      position: absolute;
      height: 45px;
      top: -60px;
      width: 45px;
    }
  }
}