@import "../../../assets/styles/Mixin.scss";
@import "../../../assets/styles/Variables.scss";


.Banner {
  font-family: $font;

  
  > .banner-path {
    display: flex;
    align-items: center;
    padding-bottom: 40px;
   

    > svg {
      width: 20px;
      height: 20px;
      padding: 5px 5px 0 5px;

      &:last-child {
        display: none;
      }
    }

   > span {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -1.7%;

     &:first-child {
      text-decoration: underline;
     }

    > a {
      color: black;
      text-decoration: none;
    }
   }

  }
}
