@import "../../../assets/styles/Mixin.scss";
@import "../../../assets/styles/Variables.scss";

.ProfileInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  
  > .top-container {
    font-family: $font;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: white;
    
  
    @include large {
      width: 792px;
    }

    > .name-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 30px;

      > .name {
        font-size: 20px;
        line-height: 28px;
        font-weight: $black;
        letter-spacing: -1.7%;
        margin: 0;

        @include medium {
          font-size: 40px;
          line-height: 68px;
        }
      }

      > img {
        margin-left: 36px;
        
        @include large {
          margin-left: 258px;
        }
      }
    }

     > .profile-image {
      position: absolute;
      top: 0px;
      left: 16px;
      width: 140px;
      height: 140px;
      
      @include medium {
        left: 40px;
        width: 180px;
        height: 180px;
      }
    }

    > .info-container {
      box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      height: 100%;
      box-sizing: border-box;
      padding: 60px 40px 40px 40px;
      padding-bottom: 0;
      position: relative;

      @include medium {
        padding: 40px;
      }

      > button {
        position: absolute;
        right: 40px;
        width: 74px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        color: white;
        background-color: $pink-natural;
        border: none;
        cursor: pointer;
        font-size: normal;
        font-weight: $bold;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -1.7%;
        text-decoration: none;
      }

      > .row-1 {
        display: flex;
        flex-direction: column;
        @include medium {
          display: grid;
        grid-template-columns: repeat(2, 220px);
        grid-template-rows: auto auto;
        margin-top: 80px;
        column-gap: 40px;
        }
      }

      > .separator {
        height: 1px;
        background-color: #E8EDEE;
        width: 300px;
        margin: 0 0 40px 0;

        @include medium {
          width: 480px;
        }
      }

      > .row-2, .row-3 {
        display: flex;
        flex-direction: column;

        @include medium {
          display: grid;
        grid-template-columns: repeat(2, 220px);
        grid-template-rows: auto auto;
        margin-top: 40px;
        column-gap: 40px;
        }
      }

      .text {
        font-size: 16px;
        line-height: 28px;
        margin-top: 0;
        font-weight: $normal;
      }

      .detail {
        font-size: 16px;
        line-height: 28px;
        margin-top: 0;
        font-weight: $bold;
        letter-spacing: -1.7%;
        margin-bottom: 40px;
      }
    } 
}

  > .bottom-container {
    font-family: $font;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    height: 100%;
    box-sizing: border-box;
    padding: 40px;
    padding-bottom: 0;
    margin-top: 40px;
    background-color: white;

    @include large {
      width: 792px;
    }

    > button {
      position: absolute;
      right: 40px;
      width: 74px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      color: white;
      background-color: $pink-natural;
      border: none;
      cursor: pointer;
      font-size: normal;
      font-weight: $bold;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -1.7%;
      text-decoration: none;
    }

    > .row-1 {
      display: flex;
      flex-direction: column;

      @include medium {
        display: grid;
      grid-template-columns: repeat(2, 220px);
      grid-template-rows: auto auto;
      margin-top: 40px;
      column-gap: 40px;
      }
    }

    > .row-2 {
      display: flex;
      flex-direction: column;

      @include medium {
        display: grid;
      grid-template-columns: repeat(2, 220px);
      grid-template-rows: auto auto;
      margin-top: 0;
      column-gap: 40px;
      }
    }

    > .separator {
      height: 1px;
      background-color: #E8EDEE;
      width: 300px;
      margin: 0 0 40px 0;

      @include medium {
        width: 480px;
      }
    }
    

    .text {
      font-size: 16px;
      line-height: 28px;
      margin-top: 0;
      font-weight: $normal;
    }

    .detail {
      font-size: 16px;
      line-height: 28px;
      margin-top: 0;
      font-weight: $bold;
      letter-spacing: -1.7%;
      margin-bottom: 40px;
    }
  }

}