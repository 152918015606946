@import "assets/styles/Mixin.scss";
@import "assets/styles/Variables.scss";

.TestimonialSlider {
  
  @include wrapper {
    display: flex;
    flex-direction: column;
    width: 90%;

    > h2 {
      color: $turquoise-title;
      font-size: 32px;
      font-weight: $extrabold;
      text-align: center;
      text-transform: uppercase;

      @include medium {
        text-transform: capitalize;
      }
    }

    > .slick-slider {
      position: relative;
      > .slick-list {
        > .slick-track {
          > .slick-slide {
            height: 100%;
          }
        }
      }

      > .prev-arrow {
        cursor: pointer;
        background-color: #ebfcff;
        border-radius: 50%;
        border: none;
        box-sizing: border-box;
        height: 40px;
        left: 0;
        line-height: 10px;
        position: absolute;
        top: 45%;
        width: 40px;
        z-index: 2;

        @include small {
          top: 35%;
        }

        @include medium {
          height: 40px;
          top: 70%;
          left: 120px;
          width: 40px;
        }

        > svg {
          color: #00b4d8;
        }
      }

      > .next-arrow {
        cursor: pointer;
        background-color: #ebfcff;
        border: none;
        border-radius: 50%;
        box-sizing: border-box;
        height: 40px;
        line-height: 10px;
        position: absolute;
        right: 0;
        top: 45%;
        width: 40px;

        @include small {
          top: 35%;
        }

        @include medium {
          height: 40px;
          top: 70%;
          width: 40px;
          right: 120px;
        }



        > svg {
          color: #00b4d8;
        }
      }
    }
  }
}
