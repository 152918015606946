@import "assets/styles/Mixin.scss";
@import "assets/styles/Variables.scss";

.TheFamily {
  @include wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 80px;
    
    @include large {
      margin-bottom: 160px;
    }

    > .title {
      color: $dark-turquoise;
      font-size: 36px;
      font-weight: $black;
      margin-bottom: 24px;

      @include large {
        font-size: 40px;
      }
    }

    > .cards-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 20px;
      width: 100%;
    }
  }
}
