@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;700;800;900&display=swap');
@import './assets/styles/Variables.scss';

/* Estilos para el scroll personalizado */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background-color: $white;
  border-radius: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: $pink-natural;
  border-radius: 6px;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Public Sans', sans-serif;
  
  figure {
    margin: 0;
  }

  section {
    margin-bottom: 160px;
  }

  .btn-primary {
    width: 90%;
    max-width: 340px;
    display: inline-block;
    border-radius: 4px;
    background-color: $pink-natural;
    color: $white;
    font-weight: $bold;
    text-align: center;
    padding-block: 10px;
  }

  .btn-secundary {
    width: 90%;
    max-width: 140px;
    display: inline-block;
    border-radius: 4px;
    background-color: $white;
    color: $pink-natural;
    font-weight: $bold;
    text-align: center;
    padding-block: 6px;
  }
}
