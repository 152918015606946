@import '../../../assets/styles/Mixin.scss';
@import'../../../assets/styles/Variables.scss';

.UserPicture {
    display: flex;
    justify-content: space-between;
    min-width: 160px;
    align-items: center;
    gap: 8px;

    > p {
        color: $pink-natural;
        border-bottom: 4px solid $pink-natural;
        padding-bottom: 1px;
    }

    > figure {
        width: 40px;
        height: 40px;

        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}