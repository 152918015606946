@import '../../../assets/styles/Mixin.scss';
@import '../../../assets/styles/Variables.scss';

.PaymentForm {
    width: 100%;
    position: relative;

    @include medium {
        width: 90%;
        padding-inline: 50px;
    }

    @include large {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 120px 1fr;
        justify-items: start;
        padding-inline: 0;
    }

    > h2 {
        font-size: 32px;
        color: $dark-turquoise;
        font-weight: $black;

        @include extraLarge {
            font-size: 40px;
            grid-column: 1 / 3;  
        }
    }

    > .woman-cloud__desktop {
        display: none;
        @include large {    
            display: block;
            width: 90%;
            max-width: 320px;
            max-height: 378px;
            grid-column: 2;
            grid-row: 2;
            position: sticky;
            top: 110px;
            bottom: 500px;
            margin-top: 80px;
            margin-bottom: 335px;
        }
        
        >img {
            width: 100%;
        }
    }

     .billingInformation {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 40px;
            background-color: $pink-light;
            border-radius: 8px;
            
        
        @include medium {
            max-width: 398px;
        }

        @include large {
            width: 90%;
        }

        .boxContainer {
            width: 90%;
        }
   
    .text {
        font-size: 14px;
        font-weight: 600;
        font-family: $font;
        line-height: 24px;
    }

    .privacy {
        color: $turquoise-natural;
    }
   }

    > .form {
        @include large {
            max-width: 100%;
            grid-column: 1;
            grid-row: 2;
        }
        > h4 {
            font-size: 24px;
            color: $turquoise;
            font-weight: $extrabold;
        }

        > .amount-cards {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 21px;

            @include large {
                grid-template-columns: repeat(4, 80px);
                grid-template-rows: 80px;
            }
            > .card {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $gray;
                padding: 26px;
                padding-inline: 0;
                border-radius: 4px;

                > p {
                    font-weight: $bold;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        > .or-break {
            text-align: center;
            font-size: 16px;
            font-weight: $bold;

            @include large {
                text-align: left;
            }

            &::before {
                content: ' ';
                display: inline-block;
                width: 40%;
                max-width: 168px;
                height: 0;
                border: 1px solid $gray;
                margin-inline-end: 16px;
            }

            &::after {
                content: ' ';
                display: inline-block;
                width: 40%;
                max-width: 168px;
                height: 0;
                border: 1px solid $gray;
                margin-inline-start: 16px;
            }
        }

        > h5 {
            color: $dark-turquoise;
            font-size: 16px;
            font-weight: $extrabold;

            &::after {
                content: ' ';
                display: inline-block;
                width: 30%;
                max-width: 270px;
                height: 0;
                border: 1px solid $gray;
                margin-inline-start: 14px;

                @include large {
                    width: 50%;
                }
            }
        }

        > label {
            &:first-of-type {
                display: flex;
                align-items: center;
            }
            > span {
                display: inline-block;
                max-width: 128px;
                margin-inline-end: 24px;
            }

            > .amount-container {
                > .dollar {
                    display: inline-block;
                    position: absolute;
                    color: $gray-medium;
                    font-weight: $normal;
                    margin-top: 25px;
                    margin-left: 8px;

                    @include medium {
                        font-size: 24px;
                        margin-top: 40px;
                    }
                }

                > #amount {
                    margin-block: 16px;
                    border-radius: 4px;
                    border: 1px solid $dark-turquoise;
                    min-height: 36px;
                    width: 70%;
                    display: inline;
                    max-width: 210px;
                    padding-inline-start: 40px;
                    outline-color: $turquoise;
                    @include medium {
                        width: 90%;
                        height: 76px;
                        font-size: 24px;
                    }
                }
            }
            > input, > select {
                display: block;
                margin-block: 16px;
                border-radius: 4px;
                border: 1px solid $dark-turquoise;
                min-height: 36px;
                width: 90%;
                max-width: 384px;
                padding-inline-start: 10px;
                outline-color: $turquoise;
                font-family: 'Public Sans', sans-serif;
            }

            > select {
                width: 95%;
            }

            > p {
                color: $pink-natural;
                font-weight: $extrabold;
            }
        }

        >.state-and-code {
            @include large {
                display: flex;
            }

            >label {
                >input {
                    width: 164px;
                    display: block;
                    margin-block: 16px;
                    border-radius: 4px;
                    border: 1px solid $dark-turquoise;
                    min-height: 36px;
                    padding-inline-start: 10px;
                    
                    &#state {
                        margin-inline-end: 24px;
                    }

                    &#zipCode {
                        text-transform: uppercase;
                    }
                }

                > p {
                    font-weight: $extrabold;
                    color: $pink-natural;
                    margin-inline-end: 8px;
                }
            }
        }

        > .MethodPayment-container {
            width: 100%;
            display: flex;
            margin-block-end: 40px;
            flex-direction: column;

            > .method  {
                display: flex;
                align-items: center;
                margin-block: 10px;
                
                > label:first-of-type {
                    margin-inline-end: 20px;
                }

                @include large {
                    margin-block-end: 40px;
                }
                > input[type="radio"] {
                    padding: 0;
                    min-width: 18px;
                    min-height: 18px;
                    margin-inline-end: 16px;

                    @include large {
                        width: 22px;
                        height: 22px;
                    }
                }
            }
        }

        > .cards-container {
            display: flex;
            width: 90%;
            max-width: 390px;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 80px;

            @include large {
                width: 100%;
                max-width: 550px;
            }

            > .card {
                width: 40px;

                @include large {
                    width: 58px;
                }

                > img {
                    width: 100%;
                }
            }
        }

        > .cardInfo {
            display: grid;
            outline-color: $turquoise;

            > .CardElement {
                border-radius: 4px;
                min-height: 36px;
                width: 90%;
                max-width: 384px;
                border: 1px solid $dark-turquoise;
                padding: 10px 10px 0;
                font-size: 10px;
            }
        }

        > .Paypal-wrapper {
            > p {
                color: $pink-natural;
                font-weight: $extrabold;
            }
        }

        > .resume-bill__cloud {
            > figure {
                width: 90%;
                max-width: 320px;
                position: relative;
                top: 110px;
                @include large {
                    display: none;
                }

                > img {
                    width: 100%;
                }
            }

            >.cloud-bill {
                min-height: 205px;
                width: 104%;
                max-width: 383px;
                min-width: 280px;
                background-image: url(https://cdn.sonarescrecer.org/images/cloud.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                z-index: 1;

                @include medium {
                    background-size: cover;
                }

                @include large {
                    display: none;
                }

                > .payment-total {
                    > p {
                        font-weight: $extrabold;
                        font-size: 10px;
                        text-align: center;
                        display: block;
                        max-width: 80px;

                        &:last-of-type {
                            color: $turquoise;
                            font-size: 16px;
                        }
                    }
                   
                }

                > .payment-recurrencing {
                    p {
                        font-weight: $extrabold;
                        font-size: 10px;
                        text-align: center;
                        max-width: 80px;

                        &:last-of-type {
                            color: $turquoise;
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        > button {
            border: none;
            margin-block-start: 92px;
            width: 100%;
            font-size: 14px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-block: 12px;
            gap: 10px;

            @include large {
                width: 220px;
            }

            > .loader {
                border: 4px solid $white;
                border-radius: 50%;
                border-top: 4px solid $pink-natural;
                width: 8px;
                height: 8px;
                -webkit-animation: spin 2s linear infinite; /* Safari */
                animation: spin 2s linear infinite;
            }
        }

    }


    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
      }
      
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }

    > .cloud-bill__desktop {
        display: none;

        @include large {
            display: block;
            min-height: 300px;
            min-width: 280px;
            width: 104%;
            max-width: 480px;
            background-image: url(https://cdn.sonarescrecer.org/images/cloud.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 1;
            grid-column: 2;
            bottom: 170px;
        }

        @include extraLarge {
            width: 74%;
        }

        > .payment-total {
            > p {
                font-weight: $extrabold;
                font-size: 10px;
                text-align: center;
                display: block;
                max-width: 80px;

                &:last-of-type {
                    color: $turquoise;
                    font-size: 16px;
                    @include large {
                        font-size: 20px;
                    }
                }

                @include large {
                    font-size: 14px;
                    max-width: 180px;
                }
            }
           
        }

        > .payment-recurrencing {
            p {
                font-weight: $extrabold;
                font-size: 10px;
                text-align: center;
                max-width: 80px;

                @include large {
                    font-size: 14px;
                    max-width: 180px;
                }

                &:last-of-type {
                    color: $turquoise;
                    font-size: 14px;

                    @include large {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

.SuccessMessage {
    color: #270;
    background-color: #DFF2BF;
    min-height: 250px;
    padding: 80px 20px;
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include large {
        grid-column: 2;
        grid-row: 2;
    }

   > h3 {
       @include large {
           font-size: 24px;
       }
    > i.success-icon {
        display: inline-block;
        width: 15px;
        height: 15px;
        background-image: url(https://cdn.sonarescrecer.org/icons/icon-success.svg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        @include large {
            width: 20px;
            height: 20px;
        }
    }

    > p {
        @include large {
            font-size: 18px;
        }
    }
   }
}

.success-image {
    margin: 20px auto;
    width: 90%;

    @include large {
        max-width: 480px;
        grid-column: 1;
        grid-row: 2;
    }

    > img {
        width: 100%;
    }
}

.ErrorMessage {
    color: #D8000C;
    background-color: #FFBABA;
    padding: 20px;
    border-radius: 5px;
    max-height: fit-content;
    margin-block: 20px;

    @include large {
        grid-column: 1;
        grid-row: 3;
    }
    > h3 {
        > i.error-icon {
            display: inline-block;
            width: 18px;
            height: 18px;
            background-image: url(https://cdn.sonarescrecer.org/icons/icon-error.svg);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}


.WarningMessage {
    color: #9F6000;
    background-color: #FEEFB3;
    text-align: center;
    padding: 20px;
    position: absolute;
    bottom: 50px;

    @include large {
        grid-column: 2;
        grid-row: 3;
    }

    > h3 {
        > i.warning-icon {
            display: inline-block;
            width: 20px;
            height: 20px;
            background-image: url(https://cdn.sonarescrecer.org/icons/icon-warning.svg);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            margin-inline-end: 10px;
            position: relative;
            top: 2px;
        }
    }
}