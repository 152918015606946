@import '../../../assets/styles/Mixin.scss';
@import '../../../assets/styles/Variables.scss';

.MentorSection {
    width: 100%;
    max-width: 996px;
    margin-inline: auto;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding-block-end: 20px;
    
    @include large {
        padding-block-end: 0;
    }

    @include wrapper {
        display: flex;
        flex-direction: column-reverse;
        @include large {
            justify-content: space-around;
            align-items: center;
            flex-direction: row;
        }

        @include extraLarge {
            justify-content: start;
        }

        > .Mentor__content {
            width: 90%;
            max-width: 450px;
            line-height: 30px;
            margin: auto;

            > h2 {
                font-size: 40px;
                font-weight: $black;
            }

            @include large {
                margin: 0;
                margin-inline: 50px;
            }

            > .button__container {
                margin-block-start: 80px;
                display: flex;
                flex-direction: column;

                @include large {
                    flex-direction: row;
                    max-height: 36px;
                    align-items: center;
                    justify-content: space-between;
                    > a:last-of-type {
                        max-width: 180px;
                    }
                }

                > a {
                    text-decoration: none;
                }

                > a:first-of-type {
                    color: white;
                    margin-block: 25px;
                }
            }
        }

        > .images-container {
            @media (max-width: 992px) {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            > .Mentor__image-container {
                display: flex;
                background-color: $pink-natural;
                min-height: 250px;
                width: 250px;
                border-radius: 24px;
                margin-block-start: 50px;
                align-items: flex-end;
                justify-content: center;
                position: absolute;
                margin-inline: auto;

                @include large {
                    width: 320px;
                    height: 480px;
                    max-width: 550px;
                }

                @include extraLarge {
                    width: 380px;
                }

    
                > .Mentor__image {
                    width: 90%;
                    min-height: 220px;

                    @include large {
                        width: 290px;
                    }
    
                    > img {
                        width: 100%;
                    }
                }
            }

            > .techNode-section {
                display: inline-block;
                background-image: url(https://cdn.sonarescrecer.org/icons/icon-tech-nodes.svg);
                background-size: cover;
                background-repeat: no-repeat;
                background-position: 50% 23%;
                width: 90%;
                max-width: 350px;
                height: 350px;

                @include large {
                    width: 420px;
                    max-width: 550px;
                    height: 550px;
                }

                @include extraLarge {
                    width: 500px;
                }
            }
        }
    }
}