@import '../../../assets/styles/Mixin.scss';
@import '../../../assets/styles/Variables.scss';

.Supporters {
    width: 100%;
    margin-top: 180px;

    @include wrapper {
        > h3 {
            font-size: 36px;
            font-weight: $extrabold;
            color: $turquoise-title;
            margin-bottom: 60px;
            text-align: center;
        }
    
        > .supporters-section {
            display: grid;
            align-items: center;
            justify-items: center;
            grid-template-columns: 1fr 1fr;

            @include large {
                grid-template-columns: repeat(5, 1fr);
                grid-template-rows: 1fr 1fr;
            }

            > .brand__supporter {
                width: 60%;
                max-width: 200px;
                margin-block: 40px;

                @include large {
                    max-width: 280px;
                }

                > img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}