@import "assets/styles/Mixin.scss";
@import "assets/styles/Variables.scss";

.SocialMedia {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 160px;

  > .title {
    color: $dark-turquoise;
    font-size: 32px;
    font-weight: $black;
    text-align: center;

    @include large {
      font-size: 40px;
    }
  }

  > .cards-container {
    align-items: center;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(180px, 180px));
    justify-content: center;
    margin-bottom: 20px;

    @include large {
      grid-template-columns: repeat(4, 180px);
    }
  }
}
