@import '../../../assets/styles/Mixin.scss';
@import '../../../assets/styles/Variables.scss';

.ContactForm {
    width: 90%;
    max-width: 1440px;
    margin: 0 auto 160px;

    > h2 {
        color: $dark-turquoise;
        font-weight: $black;
        text-align: center;
    }

    > p {
      line-height: 28px;
      text-align: center;
      max-width: 588px;
      margin: 0 auto;
    }

    > iframe {
        width: 100%;
        min-height: 650px;
        border: none;
    }

    > form {
        padding: 40px;
        background-color: $gray;
        border-radius: 8px;
        margin-block-start: 30px;
        margin-inline: auto;
        max-width: 588px;

        >h4 {
            color: $dark-turquoise;
            font-weight: $extrabold;
            font-size: 24px;
        }

        > label {
            display: block;
            margin-bottom: 6px;
        }

        > p {
            color: $pink-natural;
            font-weight: $extrabold;
            font-size: 0.8em;
        }

        > input {
             margin-bottom: 24px;
             width: 90%;
             padding-inline: 8px;
             min-height: 20px;
             max-width: 508px;
             border: 1px solid $turquoise-title;
             border-radius: 4px;
             outline-color: $dark-turquoise;

             @include medium {
                 min-height: 36px;
             }
        }

        > select {
            width: 90%;
            max-width: 508px;
            min-height: 24px;
            margin-bottom: 24px;
            border: 1px solid $turquoise-title;
            border-radius: 4px;
            padding-block: 2px;
            outline-color: $dark-turquoise;

            @include medium {
                min-height: 40px;
            }
        }

        > textarea {
            width: 90%;
            max-width: 508px;
            min-height: 131px;
            border: 1px solid $turquoise-title;
            outline-color: $dark-turquoise;
            margin-bottom: 50px;
            padding: 16px;

            @include medium {
                margin-bottom: 80px;
            }
        }

        > .captcha-container {

            @include extraLarge {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            > .captcha {
                margin-bottom: 25px;
                
                > .captcha-error {
                    color: $pink-natural;
                    font-weight: $bold;
                    margin-bottom: 8px;
                }
            }

            > button {
                border: none;
                opacity: 0.65;

                &:hover {
                    cursor: pointer;
                }

                @include large {
                    max-width: 148px;
                }
            }
        }
    }

    > .ContactForm__Img {
        width: 90%;
        max-width: 992px;
        margin: 20px auto;
        text-align: center;

        > img {
            width: 100%;

            @include medium {
                height: 100%;
                max-width: 550px;
            }
        }
    } 
}