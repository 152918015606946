@import "../../../assets/styles/Mixin.scss";
@import "../../../assets/styles/Variables.scss";

.SocialMediaCard {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 160px;

  > .icon {
    border-radius: 50%;
    height: 128px;
    margin-bottom: 37px;
    width: 128px;
  }

  > .number-followers {
    color: black;
    font-size: 40px;
    font-weight: $black;
    line-height: 68px;
  }

  > .followers {
    font-size: 16px;
    font-weight: $normal;
    line-height: 28px;
    margin-bottom: 24px;
  }

  > .btn {
    background-color: $pink-natural;
    border-radius: 4px;
    color: white;
    font-size: 14px;
    font-weight: $bold;
    padding: 6px 0;
    text-align: center;
    text-decoration: none;
    width: 100%;
  }
}
