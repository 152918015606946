@import '../../../assets/styles/Mixin.scss';
@import '../../../assets/styles/Variables.scss';

.PartnerBenefits {
    width: 100%;
    margin-top: 80px;
    > .wrapper  {
        width: 90%;
        margin: 20px auto;
        max-width: 1200px;

        > h2 {
            font-size: 40px;
            font-weight: $black;
        }

        >.table-benefits {
            display: grid;
            row-gap: 15px;
            grid-template-columns: repeat(2, 1fr);
            place-content: center;
            border: 1px solid $gray-medium;
            border-bottom: none;
            border-radius: 25px 25px 0 0;

            >h3 {
                font-size: 24px;
                text-align: center;
                border-bottom: 1px solid $gray-medium;
                padding-block: 8px;
                background-color: $dark-turquoise;
                color: $white;
                height: 100%;
                margin: 0;
                &:first-of-type {
                    border-top-left-radius: 25px;
                }

                &:last-of-type {
                    border-top-right-radius: 25px;
                }
            }

            >p {
                text-align: center;
                border-bottom: 1px solid $gray-medium;
                padding: 8px 15px;
                border-left: 1px solid $gray-medium;
                border-right: 1px solid $gray-medium;
                margin: 0;
                height: 100%;
                font-size: 18px;
                text-align: left;
            }
        }
    }
}