@import '../../../assets/styles/Mixin.scss';
@import '../../../assets/styles/Variables.scss';

.HomeMembers {
   @include wrapper {

    > .home__members-title {
        font-size: 32px;
        font-weight: $extrabold;
        color: $turquoise-title;
        text-align: center;
    }

    > .members__cards-wrapper {
        @include large {
            display: flex;
        }
    }
   }
}