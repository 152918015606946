@import '../../../assets/styles/Mixin.scss';
@import '../../../assets/styles/Variables.scss';

.JoinUsHome {
    width: 100%;
    height: 438px;
    background-color: $dark-turquoise;
    position: relative;

    @include large {
        height: 380px;
        background-color: $gray;
    }

     @include wrapper {

        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;

        @include large {
            color: black;
            justify-content: space-between;
            width: 100%;
            padding-inline-start: 20px;
        }

        @include extraLarge {
            width: 90%;
            padding-block-start: 66px;
        }
    
        > .join__content-wrapper {
            text-align: center;
            max-width: 550px;
            line-height: 28px;

            @include large {
                display: flex;
                flex-direction: column;
                justify-content: left;
            }
            
            > h3 {
                font-size: 34px;
                font-weight: $extrabold;
                @include large {
                    text-align: left;
                }
            }

            > p {
                @include large {
                    text-align: left;
                }
            }
    
            > a {
                background-color: $pink-natural;
                border-radius: 4px;
                font-weight: $bold;
                display: inline-block;
                color: $white;
                width: 90%;
                max-width: 340px;
                padding-block: 6px;
                text-decoration: none;

                @include large {
                    width: 180px;
                }
            }
        }
    
        > .joinus__image {
            display: none;
    
            > img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
            
            @include large {
                display: inline-block;
                width: 100%;
                max-width: 800px;
                height: 380px;
                position: relative;
                right: -1vw;
            }

            @include extraLarge {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
}