@import "assets/styles/Mixin.scss";
@import "assets/styles/Variables.scss";

.Testimonial {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 792px;
  width: fit-content;

  > .user-image {
    align-items: center;
    border-radius: 50%;
    border: 8px solid $turquoise;
    display: flex;
    justify-content: center;
    margin-bottom: 95px;
    width: 104px;
    object-fit: contain;

    @include medium {
      margin-bottom: 40px;
      width: 130px;
    }

    > img {
      width: 100%;
    }
  }

  > .testimonial-wrapper {
    align-items: center;
    background-color: #f3f6f6;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    position: relative;
    width: 90%;

    @include medium {
      max-width: 420px;
    }

    @include extraLarge {
      max-width: 792px;
    }

    > .quote {
      background-image: url(https://cdn.sonarescrecer.org/icons/icon-quote.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 64px;
      left: 40px;
      position: absolute;
      top: -45px;
      width: 86px;
    }

    > p {
      font-weight: $bold;
      line-height: 28px;
      margin: 0;
    }
  }
}
