@import '../../../assets/styles/Mixin.scss';
@import '../../../assets/styles/Variables.scss';

.MegaEvent {
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 750px;
    display: grid;
    place-content: center;

    >.Event-content {
        width: 90%;
        max-width: 1200px;
        margin: auto;
        text-align: center;

        >h1 {
            color: $white;
            font-size: 32px;
            line-height: 52px;
            text-align: center;

            @include extraLarge {
                font-size: 42px;
                line-height: 85px;
            }
        }

        > h2 {
            color: $white;
            font-size: 28px;
            text-align: center;
            line-height: 52px;
            letter-spacing: 2px;

            @include extraLarge {
                font-size: 32px;
                line-height: 85px;
            }

        }

        > p {
            color: $white;
            font-size: 16px;
            line-height: 28px;
            text-align: center;
            letter-spacing: 2px;

            &.live-traduction {
                font-size: 24px;
            }
        }


        >a.white-btn {
            width: 70%;
            max-width: 340px;
            border-radius: 5px;
            background-color: $white;
            color: $dark-turquoise;
            font-size: 24px;
            text-decoration: none;
            margin: 40px auto;
            font-weight: $bold;
            padding: 8px 16px;
            text-align: center;
            display: inline-block;

            &:hover {
                opacity: 0.8;
            }
        }
    }
    .Event_coming-soon {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
      }
      .Event_coming-soon P {
        font-weight: $bold;
        font-size: 28px;
      }
}