@import "assets/styles/Mixin.scss";
@import "assets/styles/Variables.scss";

.HomeDonation {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @include large {
    display: grid;
    justify-content: flex-end;
    grid-template-columns: repeat(2, 458px); // before 500px
    grid-template-rows: 100px auto;
    column-gap: 0;
    margin-inline: auto;
    padding-inline-start: 20px;

    &.left {
      grid-template-columns: repeat(2, 468px); // before they both had 588px
      margin: 0;
      margin-bottom: 260px;
    }
  }

  @include extraLarge {
    column-gap: 175px;
    justify-content: center;
  }


  &.left {

    > h3 {
      @include large {
        grid-column: 2;
      }
    }
    > .HomeDonation__image {
      grid-column: 1;
      grid-row: 1/3;
      left: 0;
      right: initial;
      > img {
        transform: scaleX(-1);
      }
    }

    > .HomeDonation__content {
      @include large {
        grid-column: 2;
      }
    }
    
  }

  > h3 {
    color: $turquoise-title;
    font-size: 32px;
    font-weight: $extrabold;
    height: min-content;
    margin: 0;
    text-align: left;
    display: flex;
    justify-content: center;
    text-transform: uppercase;

    @include large {
      align-self: flex-end;
      text-transform: none;
      justify-content: flex-start;
    }
  }

  > .HomeDonation__image {
    display: flex;
    justify-self: self-end;
    position: relative;

    > img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    @include medium {
      grid-column: 2;
      grid-row: 1 / 3;
      position: relative;
    }

    @include large {
      position: absolute;
      right: 0;
    }

  }

  > .HomeDonation__content {
    align-self: flex-start;
    height: min-content;
    margin: 0 auto;
    text-align: center;
    padding: 0 20px;

    @include large {
      margin: 0;
      text-align: left;
      width: 75%;
      padding: 0;
      grid-column: 1;
    }

    > p {
      color: $darkturquise-text;
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 24px;
      text-align: left;
      max-width: 588px;
    }

    > a {
      display: flex;
      justify-content: center;
      justify-self: center;
      text-decoration: none;
      width: 100%;
      max-width: 340px;
      margin-inline: auto;
      
      @include large {
        width: 180px;
        justify-self: flex-start;
        margin-inline: 0;
      }
    }
  }
}
