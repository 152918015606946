@import "../../../../assets/styles/Mixin.scss";
@import "../../../../assets/styles/Variables.scss";

.FooterLinks {
    font-family: $font;
    display: flex;
    align-items: center;

    > .social-links-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include medium {
        flex-direction: row;
      }

     > .social-links {
       width: 100%;
       display: flex;
       flex-direction: row;

      > .link {
        > img {
          margin-top: 8px;
          margin-right: 10px;
          width: 34px;
          height: 34px;

          @include medium {
            margin-right: 24px;
          }
        }
      }
     }
    }
}