@import "../../../assets/styles/Mixin.scss";
@import "../../../assets/styles/Variables.scss";

.VideosCards {
  width: 90%;
  height: auto;
  background: $gray;
  border-radius: 5px;
  border: none;
  font-family: $font;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 40px;
  position: relative;

  @include medium {
    padding: 40px;
    width: 730px;
    height: 425px;
    margin-bottom: 40px;
  }

  @include large {
    flex-direction: row;
    width: 876px;
    height: 490px;
    margin-bottom: 80px;
  }

  @include extraLarge {
    width: 1200px;
    height: 526px;
  }

  &.left {
    display: flex;
    flex-direction: column;

    @include large {
      flex-direction: row-reverse;
    }

    > .info-container {
        width: 240px;
      
      @include medium {
        width: 660px;
      }

      @include large {
        width: 440px;
        margin-left: 0;
        margin-right: 40px;
        }
      
      @include extraLarge {
        width: 580px;
      }
    }

  }

  > iframe {
    width: 260px;
    height: 150px;

    @include medium {
      width: 370px;
      height: 210px;
    }

  @include large {
    width: 440px;
    height: 215px;
  }
    
    @include extraLarge {
      width: 540px;
      height: 310px;
    }
  }

 > .info-container {
     width: 260px;

     @include medium {
      width: 660px;
    }

    @include large {
      width: 360px;
    margin-left: 40px;
    }

    @include extraLarge {
      width: 540px;
    }
    
  > .title {
    display: flex;
    justify-content: flex-start;
    max-height: 160px;
    font-style: normal;
    font-weight: $bold;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -1.7%;
    margin-top: 20px;

    @include medium {
      width: 660px;
      line-height: 28px;
      font-size: 18px;
    }
   
    @include large {
      width: 360px;
      margin-top: 40px;
      font-size: 22px;
      line-height: 38px;
    }

    @include extraLarge {
      width: 540px;
      font-size: 32px;
      line-height: 54px;
    }
  }

  > .description {
    font-style: normal;
    font-weight: $normal;
    font-size: 14px;
    line-height: 18px;
    margin-top: 14px;

    @include medium {
      line-height: 16px;
    }

    @include large {
      font-size: 16px;
      margin-top: 24px;
      line-height: 26px;
      
    }

    @include extraLarge {
      line-height: 28px;
    }
  }
 }

}