@import '../../../assets/styles/Mixin.scss';
@import '../../../assets/styles/Variables.scss';

.Event {
    width: 90%;
    max-width: 550px;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 15px;
    margin: 0 auto;

    > .Event__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include large {
            min-height: 307px;
            display: grid;
            grid-template-columns: 180px 1fr;
            grid-template-rows: 1/2fr 1/2fr;
            align-items: center;
            justify-items: center;
        }
        @include extraLarge {
            min-height: 240px;
        }
        > h5 {
            color: #000;
            font-weight: $extrabold;
            font-size: 16px;
            @include medium {
                height: 38px;
                grid-column: 2;
                grid-row: 1;
                min-height: 42px;
                max-height: 45px;
            }
            @include large {
                height: 50px;
                margin-bottom: 27px;
            }
        }

        > p {
            font-size: 14px;
            min-height: 96px;
            max-height: 180px;

            @include medium {
                grid-column: 2;
                grid-row: 2;
                min-height: 112px;
            }
        }

        > .Event__image {
            width: 90%;
            max-width: 320px;
            display: flex;
            justify-content: center;

            @include large {
                max-width: 180px;
                grid-column: 1;
                grid-row: 1 / 3;
            }

            > img {
                width: 100%;
                max-width: 180px;
            }
        }
    }

    > .Event__date {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include large {
            flex-direction: row;
            justify-content: space-between;
        }
        > p {
            font-size: 13px;
            display: flex;
            align-items: center; 
            min-height: 20px;
            >.icon-event {  
                height: 20px;
                width: 20px;
                display: inline-block;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                margin-inline-end: 12px;
            }

            > .calendar {
                background-image: url(https://cdn.sonarescrecer.org/icons/icon-calendar.svg);
            }

            > .clock {
                background-image: url(https://cdn.sonarescrecer.org/icons/icon-clock.svg);
            }

            > .people {
                background-image: url(https://cdn.sonarescrecer.org/icons/icon-events.svg);
            }

        }

        > a {
            text-decoration: none;

            @include large {
                max-width: 120px;
            }
        }
    }
}