@import '../../../assets/styles/Mixin.scss';
@import '../../../assets/styles/Variables.scss';

.Header {
    width: 100%;
    position: relative;
    margin-bottom: 40px;
    @include wrapper {
        padding: 10px 20px;

        @include large {
            display: grid;
            grid-template-columns: 200px 1fr;
            align-items: center;
        }

        @include extraLarge {
            min-width: 1280px;
        }

        > .header__logo-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include medium {
                padding-inline: 50px;
            }

            @include large {
                padding-inline: 0;
            }
            
            > a {
                > .Header__logo {
                    width: 50px;
                    height: 65px;

                    @include large {
                        width: 151px;
                    }

                    @include extraLarge {
                        width: 192px;
                    }

                    > picture {
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }

            > h1 {
                color: $white;
                font-size: 24px;
                font-weight: $extrabold;
                text-align: center;

                @include large {
                    display: none;
                }
            }

            > .Header__menu-icon {
                position: relative;
                z-index: 11;

                > .menu-icon {
                    width: 30px;
                    height: 30px;

                    > img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                &:hover {
                    cursor: pointer;
                }

                @include large {
                    display: none;
                }
            }
        }

        > .Header__menu-wrapper {

            @include large {
                padding-bottom: 15px;
                display: flex;
                justify-content: flex-end;
            }
            > .Header__navigation-menu {
                width: 110vw;
                min-height: 100vh;
                padding-block: 15px;
                position: fixed;
                right: -110vw;
                top: 0;
                transition: all .5s ease;
                z-index: 10;
                background: $dark-turquoise;
                padding-block-start: 40px;
                &.isOpen {
                    right: -1vw;
                }

                @include large {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    background-color: transparent;
                    position: relative;
                    right: 0;
                    top: 0;
                    width: 100%;
                    min-height: 0;
                    height: 0;
                }

                @include extraLarge {
                    width: 95%;
                }
                
                > ul { 
                    margin-inline: auto;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-around;
                    min-height: 40vh;

                    @include large {
                        min-height: 0;
                        height: 18px;
                        flex-direction: row;
                        width: 70%;
                        max-width: 670px;
                        padding-inline-end: 20px;
                        border-right: 2px solid $white;
                    }

                    > li {
                        text-align: center;
                        line-height: 28px;
                        @include large {
                            border-bottom: 4px solid transparent;
                            padding-block: 10px;

                            &:first-of-type {
                                display: none;
                            }

                            &:hover {
                                border-bottom: 4px solid $pink-natural;
                                color: $pink-natural;
                                cursor: pointer;

                                > a {
                                    color: $pink-natural;
                                }
                            }

                            &:nth-of-type(5) {
                               &:hover {
                                min-width: 96px;
                               }
                            }
                        }

                        @include extraLarge {
                            &:nth-of-type(5) {
                                &:hover {
                                    min-width: 148px;
                                }
                            }
                        }
                        
                        
                        > a {
                            color: $white;
                            text-decoration: none;
                            font-size: 14px;
                            width: fit-content;

                            @include large {
                                font-size: 9px;
                            }

                            @include extraLarge {
                                font-size: 14px;
                            }

                            &:hover {
                                color: $gray;
                            }

                            > .menu-down {
                                display: inline-block;
                                margin-inline-start: 10px;
                                width: 12px;
                                height: 8px;
                                background-image: url(https://cdn.sonarescrecer.org/icons/icon-menu-arrow.svg);
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: contain;
                                transition: all .5s ease;
                            }
                        }
                        > ul {
                            list-style: none;
                            margin: 10px 0 0 0;
                            display: none;

                           
                            @include large {
                                position: absolute;
                                padding-inline-start: 0;
                            }
                            
                            > li {
                                line-height: 28px;
                                text-align: center;

                                @include large {
                                    margin-block: 4px;
                                    min-width: 60px;
                                    text-align: right;
                                }

                                @include extraLarge {
                                    min-width: 85px;
                                }

                                > a {
                                    color: rgba(255, 255, 255, 0.7);
                                    text-decoration: none;
                                    font-size: 14px;

                                    @include large {
                                        font-size: 9px;
                                    }

                                    @include extraLarge {
                                        font-size: 14px;
                                    }

                                    &:hover {
                                        color: $white;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }

                    }

                }


                > .navigation__login {
                    display: flex;
                    min-height: 120px;
                    flex-direction: column-reverse;
                    justify-content: space-evenly;
                    align-items: center;
                    margin-top: 20px;

                    @include large {
                        flex-direction: row;
                        width: 28%;
                        margin-top: 10px;
                    }

                    > a {
                        text-decoration: none;
                        margin-bottom: 10px;
                        font-size: 14px;
                       

                        @include large {
                            font-size: 9px;

                            &:first-of-type {
                                max-width: 96px;
                                padding-block: 8px;
                            }
                        }

                        @include extraLarge {
                            font-size: 14px;

                            &:first-of-type {
                                max-width: 95px;
                            }
                        }


                        &:last-of-type {
                            color: $white;
                            display: none;
                            
                            @include large {
                                margin-inline: 10px;
                                margin-inline: 0;
                            }
                        }
                    }

                    > .logout {
                        cursor: pointer;
                    }

                    > .language-wrapper {
                        &:hover {
                             >.language-selection:last-of-type {
                                display: block;
                             }
                        }
                        > .language-selection {
                            width: 24px;
                            height: 24px;
                            border: 4px solid $white;
                            border-radius: 50%;
                            margin-block-end: 10px;
                            transition: all .4s ease-in-out;
    
                            > img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }

                            &:last-of-type {
                                display: none;
                                
                                @include large {
                                    position: absolute;
                                }
                            }

                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }


               
            }
        }
    }

   
}