@import '../../../assets/styles/Variables.scss';

.VolunteerCard {
    width: 90%;
    max-width: 385px;
    margin: 0 auto 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 426px;
    border: 1px solid $turquoise;
    border-radius: 8px;
    transition: all 1s ease;
    cursor: pointer;
    background: $blue-cards;
    overflow-y: hidden;

    &:hover {
        height: 672px;
        background: $pink-natural;
        border-color: $pink-natural;

        > h3 {
            position: relative;
        }

        > #volunteer__content {
            display: flex;
        }
    }


    > h3 {
        font-size: 32px;
        font-weight: $bold;
        color: $white;
        position: absolute;
    }

    > #volunteer__content {
        display: none;
        max-width: 293px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > p {
            color: $white;
            font-weight: $normal;
            text-align: center;
            line-height: 28px;
        }

        > a {
            text-decoration: none;
        }
    }

    > .Volunteer__image {
        > img {
            height: 100%;
            object-fit: cover;
        }
    }
}