@import "../../../assets/styles/Mixin.scss";
@import "../../../assets/styles/Variables.scss";

.ShareLinks {
    font-family: $font;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    @include large {
      flex-direction: row;
    }

    > .button-container {
      margin-bottom: 40px;

      @include large {
        margin-bottom: 0;
      }

      > .support-btn {
        width: 320px;
        height: 36px;
        margin-top: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        color: white;
        background-color: $pink-natural;
        border: none;
        cursor: pointer;
        font-size: normal;
        font-weight: $bold;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -1.7%;
        text-decoration: none;
  
        @include medium {
          width: 180px;
        }
      }
    }
  
    > .social-links-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include medium {
        flex-direction: row;
      }

      > .text {
        font-size: 16px;
        line-height: 28px;
        font-weight: $extrabold;
        font-style: normal;
        letter-spacing: -1.7%;

       @include medium {
        margin: 6px 18px 0px 22px;
       }
      }

     > .social-links {
       width: 100%;
       display: flex;
       flex-direction: row;
       justify-content: space-around;

       @include large {
         justify-content: flex-start;
       }

      > .link {
        &.copy {
          cursor: pointer;
        }
        
        > img {
          margin-top: 8px;
          width: 34px;
          height: 34px;

          @include large {
            margin-right: 28px;
          }
        }
      }
     }
    }
}