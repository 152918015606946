@import "../../../assets/styles/Mixin.scss";
@import "../../../assets/styles/Variables.scss";

.SuccessRegistration {
    width: 80%;
    color: $white;
    display: flex;
    gap: 25px;
    margin: 25px auto;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

    @include large {
        flex-direction: row;
    }

    >.success-content {
        background-color: $turquoise-medium;
        padding: 16px;
        display: grid;
        place-content: center;
        border-radius: 8px;
        text-align: center;
        max-width: 550px;
    }

    >picture {
        >img {
            width: 100%;
        }
    }
}