@import '../../../assets/styles/Variables.scss';
@import '../../../assets/styles/Mixin.scss';


.ContactMessage {
    width: 90%;
    max-width: 1440px;
    margin: 100px auto 160px;

    > h1 {
        color: $dark-turquoise;
        font-weight: $black;
        text-align: center;
        font-size: 40px;
    }

    > p {
        line-height: 28px;
        max-width: 750px;
        margin: 0 auto;
        color: $dark-turquoise;
        font-size: 18px;

        > a {
            font-weight: $bold;
        }
    }

    > .SocialMedia {

        margin-block: 50px;

        > h2.title {
            display: none;
        }

        > .cards-container {
            > .SocialMediaCard {
                > span.number-followers, 
                > span.followers {
                    display: none;
                }
            }
        }
    }

}