@import '../../../assets/styles/Mixin.scss';
@import '../../../assets/styles/Variables.scss';

.Hero {
    min-height: 781px;
    width: 100%;
    background: $backgrond-heroColor;
    margin-bottom: 0;
    padding-block-start: 80px;

    @include medium {
        padding-block-start: 120px;
    }
    
    @include large {
        min-height: auto;
        height: 590px;
    }

    @include extraLarge {
        height: 660px;
    }

    @include wrapper {
            margin-top: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;

            @include  medium {
                margin-top: 0;
            }
            
            @include large {
                flex-direction: row;
                justify-content: space-around;
            }
    

        > .hero__text-content {
            max-width: 325px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            min-height: 500px;
            text-align: center;
            color: $white;
    
            @include large {
                min-height: 264px;
            }

            @include extraLarge {
                max-width: 486px;
            }
    
            > h2 {
                text-align: left;
                font-size: 40px;
                font-weight: $black;
            }
            
            > p {
                font-size: 18px;
                font-weight: $normal;
                text-align: left;
                line-height: 28px;
            }
        }
    
    
        > .hero__image {
            position: relative;
            bottom: -4px;
            max-width: 750px;

    
            @include large {
                width: 1120px;
                height: 680px;
                align-self: flex-end;
                position: initial;
                margin-top: 38px;
            }
    
            > img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            } 
        }
    }
}