@import "assets/styles/Mixin.scss";
@import "assets/styles/Variables.scss";

.JoinTheFamily {
  padding: 0 20px;
  margin-bottom: 80px;

  @include large {
    align-items: center;
    background-color: $gray;
    column-gap: 20px;
    display: grid;
    flex-direction: row;
    grid-template-columns: minmax(auto, 689px) minmax(auto, 384px);
    justify-content: center;
    margin-bottom: 160px;
    padding: 48px 120px;
  }

  @include extraLarge {
    grid-template-columns: 689px 384px;
    column-gap: 127px;
  }

  > .cloud-wrapper {
    background-image: url("https://cdn.sonarescrecer.org/images/sara-cloud.png");
    background-position: center;
    background-origin: content-box;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    height: 482px;
    width: 100%;

    @include large {
      display: flex;
      max-width: 689px;
      width: 100%;
    }

    > .empty-space {
      width: 45%;

      @include large {
        width: 350px;
      }
    }

    > .sara-info {
      height: min-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: center;

      > .title {
        color: $turquoise-natural;
        font-size: 30px;
        font-weight: $extrabold;
        margin-bottom: 8px;
        margin-top: 0;

        @include medium {
          font-size: 32px;
        }
      }

      > .charge {
        font-size: 12px;
        font-weight: $normal;
        margin-bottom: 50px;

        @include medium {
          font-size: 16px;
          margin-bottom: 0;
          margin-bottom: 79px;
        }
      }

      > .quote {
        color: $turquoise-title;
        font-size: 12px;
        font-weight: $extrabold;
        position: relative;

        @include medium {
          font-size: 16px;
        }

        > .quote-img {
          background-image: url("https://cdn.sonarescrecer.org/icons/icon-quote.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          left: 0;
          position: absolute;
          top: -45px;
          height: 40px;
          width: 40px;

          @include medium {
            height: 63px;
          top: -65px;

            width: 63px;
          }
        }
      }
    }
  }

  > .join-info {
    width: 100%;

    @include large {
      max-width: 384px;
      width: 100%;
    }

    > .title {
      color: $darkturquise-text;
      font-size: 32px;
      font-weight: $extrabold;
      line-height: 54px;
      margin: 0 0 16px;
    }

    > .description {
      color: black;
      font-size: 16px;
      font-weight: $normal;
      line-height: 28px;
      margin-bottom: 24px;
    }

    > .btn {
      align-items: center;
      background-color: $pink-natural;
      border-radius: 4px;
      color: $white;
      display: flex;
      font-weight: $bold;
      height: 36px;
      justify-content: center;
      max-width: 180px;
      padding: 6 auto;
      text-align: center;
      text-decoration: none;
    }
  }
}
