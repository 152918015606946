@import "assets/styles/Mixin.scss";
@import "assets/styles/Variables.scss";

.OurVolunteers {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;

  @include large {
    margin-bottom: 160px;
  }

  @include wrapper {
    > .title {
      color: black;
      font-size: 32px;
      font-weight: $black;
      margin: 0 0 24px 0;

      @include large {
        font-size: 40px;
      }
    }

    > img {
      height: auto;
      width: 100%;
    }
  }
}
