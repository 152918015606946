@import "../../../assets/styles/Mixin.scss";
@import "../../../assets/styles/Variables.scss";

.DonationsCards {
  width: 282px;
  height: 554px;
  background: $blue-cards;
  border-radius: 5px;
  border: none;
  font-family: $font;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  box-sizing: border-box;

  &.is-small {
    width: 384px;
    height: 406px;
    padding: 40px;
    box-sizing: border-box;

    > img {
      width: 80px;
      height: 80px;
    }

    > .title {
      margin-top: 10px;
    }

    > .description {
      height: 84px;
      margin-bottom: 40px;
    }

    .btn-container {
      > .btn-card {
        width: 304px;
        height: 36px;
      }

      > img { 
        right: -40px;
        bottom: -22px;
      }
    }
  }

  > img {
    width: 122px;
    height: 122px;
    
  }

  > .title {
    display: flex;
    justify-content: center;
    width: 156px;
    min-height: 80px;
    color: white;
    font-style: normal;
    font-weight: $bold;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: -1.7%;
    text-align: center;
  }

  > .description {
    height: 202px;
    color: white;
    font-style: normal;
    font-weight: $normal;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    margin-top: 0;
  }

 > .btn-container {
   position: relative;

  > .btn-card {
    width: 202px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: $turquoise;
    font-family: $font;
    font-weight: $bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -1.7%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 4;
    position: relative;
    text-decoration: none;
  }

  > img {
    position: absolute;
    right: -40px;
    bottom: -40px;
    z-index: 1;
  }
 }
}