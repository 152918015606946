@import '../../../assets/styles/Mixin.scss';
@import '../../../assets/styles/Variables.scss';

.UpcomingEvents {
    width: 100%;

    @include wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; 

        > h3 {
            color: $dark-turquoise;
            font-weight: $black;
            font-size: 40px;
            text-align: center;

            @include large {
                text-align: left;
                flex: 1;
            }
        }

        > .events-section {

            @include medium {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-auto-rows: minmax(250px, 1fr);
            }
        }
    }
}