@import "assets/styles/Mixin.scss";
@import "assets/styles/Variables.scss";

.MeetTheTeam {
  @include wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
    
    @include medium {
      margin-bottom: 160px;
    }

    > .title {
      color: black;
      font-weight: 900;
      margin: 0 0 24px 0;
      font-size: 32px;

      @include large {
        line-height: 68px;
        font-size: 40px;
      }
    }

    > .subtitle {
      color: black;
      font-size: 24px;
      font-weight: 800;
      line-height: 40px;
      margin: 0 0 24px 0;

      &.mt-40 {
        margin-top: 80px;
      }

      > span {
        margin: 0 10px;
        font-weight: 400;
      }
    }

    > .cards-container {
      align-items: center;
      justify-content: center;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-gap: 24px;
    }
  }
}
