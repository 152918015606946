@import '../../../assets/styles/Mixin.scss';
@import '../../../assets/styles/Variables.scss'; 

.container {
  height: 100vh;
  width: 100vw;
  font-family: Helvetica;
}

.loader {
  height: $loader-dot--size;
  width: $loader--width;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  &--dot {
    animation: loader ease-in-out 3s infinite;
    height: $loader-dot--size;
    width: $loader-dot--size ;
    border-radius: 100%;
    background-color: black;
    position: absolute;
    border: 2px solid white;

    &:first-child {
      background-color: $pink-darkest;
      animation-delay: 0.5s;
    }
    &:nth-child(2) {
      background-color: $pink-natural;
      animation-delay: 0.4s;
    }
    &:nth-child(3) {
      background-color: $turquoise-natural;
      animation-delay: 0.3s;
    }
    &:nth-child(4) {
      background-color: $turquoise-medium;
      animation-delay: 0.2s;
    }
    &:nth-child(5) {
      background-color: $turquoise;
      animation-delay: 0.1s;
    }
    &:nth-child(6) {
      background-color: $turquoise-title;
      animation-delay: 0s;
    }
  }

  
  &--text-en {
    position: absolute;
    top: 200%;
    left: 0;
    right: 0;
    width: 4rem;
    margin: auto;

    &:after {
      content: "Loading";   
      font-weight: bold;
      animation: loading-text-en 3s infinite;
    }
  }

  &--text-es {
    position: absolute;
    top: 200%;
    left: 0;
    right: 0;
    width: 4rem;
    margin: auto;

    &:after {
      content: "Cargando";   
      font-weight: bold;
      animation: loading-text-es 3s infinite;
    }
  }
}
    
@keyframes loader {
  15% {
    transform: translateX(0)
  }
  45% {
    transform: translateX( $loader--width - $loader-dot--size )
  }
  65% {
    transform: translateX( $loader--width - $loader-dot--size )
  }
  95% {
    transform: translateX(0)
  }
}


@keyframes loading-text-en{
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
}

@keyframes loading-text-es{
  0% {
    content: "Cargando";
  }
  25% {
    content: "Cargando.";
  }
  50% {
    content: "Cargando..";
  }
  75% {
    content: "Cargando...";
  }
}